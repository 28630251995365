.image {
  width: 100%;
  height: auto;
}

.paper {
  min-height: 6.5rem;
  box-shadow: none;
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
}

.logo {
  max-height: 6.5rem;
  width: 100%;
  object-fit: contain;
  margin: 0 auto;
}
