// Colour Palette
$primaryDark: #001933;
$primaryMain: #115194;
$primaryLight: #3f71be;
$primaryLighter: #7299d5;
$primaryLightest: #eaeef6;
$primaryContrastText: #fff;
$secondaryDarkest: #333;
$secondaryDark: #696969;
$secondaryMain: #9c9c9c;
$secondaryLight: #d7d7d7;
$secondaryLightest: #f8f8f8;
$white: #fff;
