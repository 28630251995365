@import 'styles/partials/variables';

.container {
  background-color: $primaryDark;
  display: flex;
  justify-content: center;
  padding: 2rem;
  min-height: 100%;
}

.logo {
  display: flex;
}
