@import 'styles/partials/variables';
@import 'styles/partials/mixins';

.sideContainer {
  background-color: $primaryDark;
  border-radius: 12px;

  @include sm {
    background-image: url('../../../assets/Pattern.svg');
    background-repeat: no-repeat;
    background-position: bottom left;

    @media screen and (max-height: 885px) {
      background-position-y: 18rem;
    }
  }
}

.gridContainer {
  flex: 1;
  justify-content: center;
}

.divider {
  text-transform: uppercase;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
